<template>
  <div id="map">
    <info-drawer :options.sync="options" />

    <v-progress-linear
      v-if="loading"
      indeterminate
    />

    <l-map
      ref="map"
      class="leaflet-map"
      :options="mapOptions"
    >
      <l-tile-layer url="https://api.mapbox.com/styles/v1/darkomk/cju7fjs4s2kl41golvx7ufzer/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiamFucnVsczEiLCJhIjoiY2swcGlhNXpwMDBzNTNvcmcwZDQxcDB6cyJ9.w3WTQktdZ2nMziZtXNoRKQ" />

      <l-control-zoom position="topright" />

      <!-- <l-layer-group>
        <l-marker
          v-for="customer in customers"
          :key="'initial-' + customer.id"
          :lat-lng="[Number(customer.latitude), Number(customer.longitude)]"
          :icon="statusIcon(customer.status_icon)"
        >
          <l-tooltip>
            {{ customer.name }}
          </l-tooltip>
        </l-marker>

        <l-marker
          v-for="customer in customers"
          :key="'initial-plus-360-' + customer.id"
          :lat-lng="[customer.latitude, Number(customer.longitude) - 360]"
          :icon="statusIcon(customer.status_icon)"
        >
          <l-tooltip>
            {{ customer.name }}
          </l-tooltip>
        </l-marker>
      </l-layer-group> -->
    </l-map>
  </div>
</template>

<script>
  import * as Vue2Leaflet from 'vue2-leaflet'
  import * as L from 'leaflet'
  import 'leaflet.markercluster'
  import axios from 'axios'
  import { mapActions } from 'vuex'

  export default {
    components: {
      LMap: Vue2Leaflet.LMap,
      LTileLayer: Vue2Leaflet.LTileLayer,
      // LLayerGroup: Vue2Leaflet.LLayerGroup,
      // LMarker: Vue2Leaflet.LMarker,
      // LTooltip: Vue2Leaflet.LTooltip,
      LControlZoom: Vue2Leaflet.LControlZoom,

      InfoDrawer: () => import('./InfoDrawer'),
    },

    data: () => ({
      map: null,
      mapOptions: {
        zoomControl: false,
        zoom: 2,
        maxZoom: 20,
        maxBounds: [[-90, -540], [90, 180]],
      },
      loading: false,
      customers: [],
      clusterLayer: L.markerClusterGroup({ disableClusteringAtZoom: 12 }),
      options: {},
    }),

    mounted () {
      this.map = this.$refs.map.mapObject
      this.$nextTick(() => {
        this.getAll()
      })
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async getAll () {
        this.loading = true
        try {
          const response = await axios.get('map')
          this.customers = response.data
          this.renderClusterLayer()
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.loading = false
      },

      renderClusterLayer () {
        this.customers.forEach(customer => {
          if (customer.latitude && customer.longitude) {
            // Initial
            L.marker(
              L.latLng([customer.latitude, customer.longitude]),
              {
                radius: 5,
                icon: this.statusIcon(customer.status_icon),
              },
            )
              .bindTooltip(customer.name)
              .on('click', () => (this.options = { show: true, customer }))
              .addTo(this.clusterLayer)

            // Initial - 360 degrees
            L.marker(
              L.latLng([customer.latitude, customer.longitude - 360]),
              {
                radius: 5,
                icon: this.statusIcon(customer.status_icon),
              },
            )
              .bindTooltip(customer.name)
              .on('click', () => (this.options = { show: true, customer }))
              .addTo(this.clusterLayer)
          }
        })
        this.map.addLayer(this.clusterLayer)
      },

      statusIcon (icon) {
        return L.divIcon({
          html: `<i class="v-icon notranslate mdi mdi-${icon}" aria-hidden="true" style="color: #174446"></i>`,
          className: 'custom-svg-icon',
        })
      },
    },
  }
</script>

<style lang="sass">
  @import "~leaflet.markercluster/dist/MarkerCluster.css"
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css"

  #map
    height: 100%
    .leaflet-map
      height: 100%
      z-index: 0
</style>
